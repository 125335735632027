import "./residentialPage.css";
import SearchBar from "../../Components/SearchBar/SearchBar"
import DispCard from "../../Components/Display/DispCard";
import Advertisements from "../../Components/Advertisements/Advertisements";


const ResidentialPage = () => {

    let ad1 = "https://res.cloudinary.com/djjcged23/image/upload/v1725521923/ocjlcm6hfqxurstnnb19.png";
    let ad2 = "";

    const ads = [
        ad1,
        ad2,
    ].filter(plan => plan); 


    return(
        <>
        
        <div className="residential">

            <div className="searchBarDiv">
                <SearchBar pageType="residential"/>
            </div>

            <div className="titleDiv">
                <h1>Residential Properties</h1>
            </div>

            <div className="ads">
                <Advertisements pageType="residential"/>
            </div>

            <div className="outputDiv">
            <DispCard pageType="residential"/>
            </div>

            <div className="extraSpace"></div>
            
        </div>
        </>
    );
}

export default ResidentialPage;