import "./footer.css";

const Footer = () => {
    return(
        <div className="footer">
                <h3 className="text">A Division of R.L Industries and Groups</h3>
                <h3 className="number">+91 81081 08689</h3>
        </div>
    );
}
export default Footer;