import "./cardResidential.css";
import {Link} from "react-router-dom";

const ResidentialCard = (props) => {

    let displayText = props.category === "rental" ? "Rent" : "Budget";

    const priceConverter = (value) => {
        if (value >= 10000000) {
          return `${(value / 10000000).toFixed(2)} Cr`;
        } else if (value >= 100000) {
          return `${(value / 100000).toFixed(2)} L`;
        } else if (value >= 1000) {
          return `${(value / 1000).toFixed(2)} K`;
        } else {
          return value.toString();
        }
    };

    function decodeHTMLEntities(str) {
        return str.replace(/&amp;/g, '&');
    }


    return(
        <>
            <div className="residential-card">
                <div className="residential-card-title">
                    <h1>{props.title}</h1>
                </div>
                <div className="residential-card-location">
                    <h2>Location : {props.location}</h2>
                </div>
                <div className="residential-card-budget">
                    <h2>{displayText} : {priceConverter(props.price)}</h2>
                </div>
                <div className="residential-card-type">
                    <h2>Type : <span className="capitalize">{props.property_type}</span></h2>
                </div>
                <div className="residential-card-bhk">
                    <h2 className="bhk">BHK : {props.bhk}</h2>
                </div>
                <div className="residential-card-des">
                    <h2 className="des">{decodeHTMLEntities(props.description)}</h2>
                </div>
                <div className="residential-card-btn">
                    <Link
                        to={`/residential/viewmore/${props._id}`}
                        className="btn"
                        state={{ cardDetails: props }}>
                        View More
                    </Link>
                </div>
            </div>
        
        </>
    );
}

export default ResidentialCard;