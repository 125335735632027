import "./newProjects.css";
import SearchBar from "../../Components/SearchBar/SearchBar";
import NewProjectCard from "../../Components/Cards/NewProjectCard";
import {useState, useEffect} from "react";
import axios from 'axios';



const NewProjects = () => {

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [ratingData, setRatingData] = useState([]);


    useEffect(() => {
        let url = "https://property-stock-backend.vercel.app/project/approved";


        axios.get(url)
            .then((res) => {
                const sortedData = res.data.response.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
                // const sortedData = res.data.response;
                // const sortedData = res.data.response.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));

                setData(sortedData);
                // console.log(sortedData);
                setRatingData(sortedData.sort((a, b) => Number(b.rating) - Number(a.rating)));
                // console.log(ratingData);
            })
            .catch((err) => {
                console.log(err);
            })
            .finally(() => {
                setLoading(false);
            });
    }, []);


    return(
        <div className="newProjects">
            <div className="searchBarDiv">
                <SearchBar pageType="newprojects"/>
            </div>

            <div className="titleDiv">
                <h1>New Projects</h1>
            </div>
            {loading ? <div className="spinner"></div> : ""}

            <div className="outputDiv">
                {ratingData.map((project, index) => (
                    <NewProjectCard key={index} project={project} />
                ))}
            </div>

            <div className="extraSpace"></div>
        </div>
    );
}

export default NewProjects;
