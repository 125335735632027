import "./navbar.css";
import Logo from "../../Assets/logo.svg";
import menuIcon from "../../Assets/menuIcon.svg";
import {Link} from "react-router-dom";
import {useState, useEffect} from "react";
import NewMenu from "../NewMenu/NewMenu";
import axios from "axios";

const NavBar = ({ onMenuButtonClick }) => {

    const [showAddBtn, setShowAddBtn] = useState(false);
  
    useEffect(() => {
      const fetchData = async () => {
        try {
          const response = await axios.get('https://property-stock-backend.vercel.app/super-admin/advertisement');
          setShowAddBtn(response.data.response.addProject);
        //   console.log(response.data.response.addProject);
        } catch (error) {
          console.error('Error fetching Ads:', error);
        }
      };
  
      fetchData();
    }, []);

    

    const [isNewMenuVisible, setIsNewMenuVisible] = useState(false);

    const handleFlBtnClick = () => {
        setIsNewMenuVisible(!isNewMenuVisible);
    };

    const hideMenu = () => {
        setIsNewMenuVisible(false);
    };


    return(
        <div className="navbar">
            <div className="logoContainer">
                <img  src={Logo} alt="Logo"/>
            </div>

            <div className="textContainer">
                <Link className="link" to="/">PropertiesStock</Link>
            </div>

            <div className="menuContainer">
                <button onClick={onMenuButtonClick}><img src={menuIcon} alt="Menu Icon"/></button>
            </div>

            {showAddBtn && (
                <button className="flBtn" onClick={handleFlBtnClick}>Add Properties <span className="flBtnRed">FREE</span></button>
            )}

            {/* {isNewMenuVisible && <NewMenu hideMenu={hideMenu}/>} */}
            {showAddBtn && isNewMenuVisible && <NewMenu />}


        </div>
    );
}

export default NavBar;