import './App.css';
import NavBar from './Components/NavBar/NavBar';
import HomePage from './Pages/HomePage/HomePage';
import Footer from './Components/Footer/Footer';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import ResidentialPage from "./Pages/ResidentialPage/ResidentialPage";
import CommercialPage from "./Pages/CommercialPage/CommercialPage";
import LandPlotPage from "./Pages/LandPlotPage/LandPlotPage";
import FinancialPage from "./Pages/FinancialPage/FinancialPage";
import LogisticsPage from "./Pages/LogisticsPage/LogisticsPage";
import OurServicesPage from "./Pages/OurServicePage/OurServicesPage";
import React, { useState } from 'react';
import MenuItems from './Components/MenuItems/MenuItems';
import ViewMore from './Components/ViewMore/ViewMore';
import RFilter from './Components/Filter/RFilter';
import CFilter from './Components/Filter/CFilter';
import PLFilter from './Components/Filter/PLFilter';
import ResidentialResults from './Pages/ResidentialPage/ResidentialResults';
import CommercialResults from "./Pages/CommercialPage/CommercialResults";
import LandPlotResults from "./Pages/LandPlotPage/LandPlotResults";
import NewProjects from './Pages/NewProjectsPage/NewProjects';
import NewProjectViewMore from './Components/ViewMore/NewProjectViewMore';
import AddProperties from "./Pages/AddProperties/AddProperties";
import AddNewProject from './Pages/AddProperties/AddNewProject';
import NPFilter from './Components/Filter/NPFilter';
import NewProjectResults from "./Pages/NewProjectsPage/NewProjectResults";
import 'leaflet/dist/leaflet.css';



function App() {

  const [isMenuVisible, setMenuVisible] = useState(false);

  const toggleMenu = () => {
    setMenuVisible(!isMenuVisible);
  };

  const closeMenu = () => {
    setMenuVisible(false); // Close the menu
  };

  return (
    <div className="App">
      <Router>
        <NavBar onMenuButtonClick={toggleMenu}/>
        {isMenuVisible && <MenuItems onCloseMenu={closeMenu} />}
        <Routes>
          <Route path = "/" element = {<HomePage/>} />
          <Route path = "/residential" element = {<ResidentialPage/>} />
          <Route path = "/commercial" element = {<CommercialPage/>} />
          <Route path = "/plot&land" element = {<LandPlotPage/>} />
          <Route path = "/financial" element = {<FinancialPage/>} />
          <Route path = "/logistics" element = {<LogisticsPage/>} />
          <Route path = "/ourservices" element = {<OurServicesPage/>} />
          <Route path = "/newprojects" element = {<NewProjects/>} />
          {/* <Route path="/viewmore/:id" element={<ViewMore />} /> */}

          <Route path="/residential/viewmore/:id" element={<ViewMore />} />
          <Route path="/commercial/viewmore/:id" element={<ViewMore />} />
          <Route path="/plot&land/viewmore/:id" element={<ViewMore />} />
          <Route path="/newprojects/viewmore/:id" element={<NewProjectViewMore />} />


          <Route path="/addproperty" element={<AddProperties />} />
          <Route path="/addproperty/newproject" element={<AddNewProject />} />


          <Route path="/residential/filter" element={<RFilter />} />
          <Route path="/commercial/filter" element={<CFilter />} />
          <Route path="/plot&land/filter" element={<PLFilter />} />
          <Route path="/newprojects/filter" element={<NPFilter />} />
          <Route path="/residential/results" element={<ResidentialResults />} />
          <Route path="/commercial/results" element={<CommercialResults />} />
          <Route path="/plot&land/results" element={<LandPlotResults />} />
          <Route path="/newprojects/results" element={<NewProjectResults />} />

          <Route path="*" element={ <Navigate to="/"/> } />
        </Routes>
      </Router>
      <Footer/>
    </div>
  );
}

export default App;