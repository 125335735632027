import "./homemenu.css";
import ResidentialIcon from "../../Assets/residential.svg";
import CommercialIcon from "../../Assets/commercial.svg";
import LandPlotIcon from "../../Assets/landplot.svg";
import FinancialIcon from "../../Assets/financial.svg";
import LogisticsIcon from "../../Assets/logistics.svg";
import OurServicesIcon from "../../Assets/ourservices.svg";
import NewProjectIcon from "../../Assets/newProject.svg";
import {Link} from "react-router-dom";

const HomeMenu = () => {
    return(
        <>
            <div className="fullMenu">
        
            <Link className="link" to="/residential">
                <div className="item">
                    <img className="logo" src={ResidentialIcon} alt="Residential Icon"></img>
                    <h3>Residential<br/>Properties</h3>
                </div>
            </Link>

            <Link className="link" to="/newprojects">
                <div className="item">
                    <img className="logo" src={NewProjectIcon} alt="New Project Icon"></img>
                    <h3>New Projects</h3>
                </div>
            </Link>

            <Link className="link" to="/commercial">
                <div className="item">
                    <img className="logo" src={CommercialIcon}alt="Commercial Icon"></img>
                    <h3>Commercial<br/>Properties</h3>
                </div>
            </Link>

            <Link className="link" to="/plot&land">
                <div className="item">
                    <img className="logo" src={LandPlotIcon} alt="Plot&Land Icon"></img>
                    <h3>Plots / Lands</h3>
                </div>
            </Link>

            <Link className="link" to="/financial">
                <div className="item">
                    <img className="logo" src={FinancialIcon} alt="Financial Icon"></img>
                    <h3>Financial /<br/>Investment</h3>
                </div>
            </Link>

            <Link className="link" to="/logistics">
                <div className="item">
                    <img className="logo" src={LogisticsIcon} alt="Logistics Icon"></img>
                    <h3>Logistics /<br/>Packers<br/>and Movers</h3>
                </div>
            </Link>

            <Link className="link" to="/ourservices">
                <div className="item">
                    <img className="logo" src={OurServicesIcon} alt="Our Services Icon"></img>
                    <h3>Our Services</h3>
                </div>
            </Link>
            
            </div>
        </>
    );
}

export default HomeMenu