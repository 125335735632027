import "./searchbar.css";
import searchLogo from "../../Assets/searchlogo.svg";
import { Link} from "react-router-dom";

const SearchBar = (props) => {

    let url;
    let pageType = props.pageType;

    if(pageType === "residential"){
        url = "/residential/filter";
    }
    else if(pageType === "commercial"){
        url = "/commercial/filter";
    }
    else if(pageType === "plot&land"){
        url = "/plot&land/filter";
    }
    else if(pageType === "newprojects"){
        url = "/newprojects/filter";
    }
    else{
        url = "/";
    }

    return(
        <>
            <Link to={url} className="searchBar"> Search <img src={searchLogo} alt="SearchLogo"/></Link>
            {/* <Link to="/test">Test</Link> */}
            
        </>
    );
}

export default SearchBar;