import "./logisticsPage.css";
import Whatsapp from "../../Components/Whatsapp/Whastapp";
import HomeMenu from "../../Components/HomeMenu/HomeMenu";
import WAIcon from "../../Assets/whatsapp.svg";
import Advertisements from "../../Components/Advertisements/Advertisements";

const LogisticsPage = () => {

        const redirectToWhatsApp = () => {
                const whatsappURL = "https://wa.me/+917718858689"; 
                window.location.href = whatsappURL;
        };

        let ad1 = "https://res.cloudinary.com/djjcged23/image/upload/v1725521923/ocjlcm6hfqxurstnnb19.png";

    const ads = [
        ad1,
    ].filter(plan => plan); 


    return(
        <>
        <div className="logistics">
        
            <div className="homeMenu">
            {/* <HomeMenu/> */}
            </div>

            <div className="top">
                <div className="text">
                    <h1>Logistics / Packers and Movers</h1>
                </div>
                <div className="whatsapp">
                <Whatsapp/>
                </div>
            </div>

            <div className="ads">
                <Advertisements pageType="logistics"/>
            </div>

            <div className="services">
                <h2>&bull; 3 PL Services</h2>
                <h2>&bull; Packaging Services</h2>
                <h2>&bull; Transportation Services</h2>
                <h2>&bull; Warehousing Services</h2>
                <h2>&bull; Distribution Services</h2>
                <h2>&bull; Import/Export Clearance</h2>
                <h2>&bull; Cargo Insurance</h2>
                <h2>&bull; Logistics Insurance</h2>
                <div className="extra">

                </div>
            </div>

            <div className="servicewithWA">
                <div className="WAService">
                        <h2>&bull; 3 PL Services</h2> <img onClick={redirectToWhatsApp} className="IconWA" src={WAIcon} alt="WA Logo"></img>
                </div>
                <div className="WAService">
                        <h2>&bull; Packaging Services</h2> <img onClick={redirectToWhatsApp} className="IconWA" src={WAIcon} alt="WA Logo"></img>
                </div>
                <div className="WAService">
                        <h2>&bull; Transportation Services</h2> <img onClick={redirectToWhatsApp} className="IconWA" src={WAIcon} alt="WA Logo"></img>
                </div>
                <div className="WAService">
                        <h2>&bull; Warehousing Services</h2> <img onClick={redirectToWhatsApp} className="IconWA" src={WAIcon} alt="WA Logo"></img>
                </div>
                <div className="WAService">
                        <h2>&bull; Distribution Services</h2> <img onClick={redirectToWhatsApp} className="IconWA" src={WAIcon} alt="WA Logo"></img>
                </div>
                <div className="WAService">
                        <h2>&bull; Import/Export Clearance</h2> <img onClick={redirectToWhatsApp} className="IconWA" src={WAIcon} alt="WA Logo"></img>
                </div>
                <div className="WAService">
                        <h2>&bull; Cargo Insurance</h2> <img onClick={redirectToWhatsApp} className="IconWA" src={WAIcon} alt="WA Logo"></img>
                </div>
                <div className="WAService">
                        <h2>&bull; Logistics Insurance</h2> <img onClick={redirectToWhatsApp} className="IconWA" src={WAIcon} alt="WA Logo"></img>
                </div>
                <div className="extra">

                </div>
            </div>

        </div>
        </>
    );
}

export default LogisticsPage;