import "./newmenu.css";
import {Link} from "react-router-dom";

const NewMenu = ({ hideMenu }) => {

    return(
        <>
            <div className="flMenu">  
                <Link to="/addproperty?type=residential" className="flLink" onClick={hideMenu}>Residential</Link>
                <Link to="/addproperty/newproject" className="flLink" onClick={hideMenu}>New Projects</Link>
                <Link to="/addproperty?type=commercial" className="flLink" onClick={hideMenu}>Commercial</Link>
                <Link to="/addproperty?type=plot" className="flLink" onClick={hideMenu}>Plots / Lands</Link>
            </div>
        </>
    );
}

export default NewMenu;