import "./menuitems.css";
import {Link} from "react-router-dom";

const MenuItems = ({ onCloseMenu }) => {
    const handleItemClick = () => {
        // Call the onCloseMenu function passed from the parent component
        onCloseMenu();
      };

    return(
        <>
            <div className="btnMenu">
                <div className="dispMenu">
                        <Link onClick={handleItemClick} className="link" to="/residential">Residential Properties</Link>
                        <Link onClick={handleItemClick} className="link" to="/newprojects">New Projects</Link>
                        <Link onClick={handleItemClick} className="link" to="/commercial">Commercial Properties</Link>
                        <Link onClick={handleItemClick} className="link" to="/plot&land">Plots / Lands</Link>
                        <Link onClick={handleItemClick} className="link" to="/financial">Financial / Investment</Link>
                        <Link onClick={handleItemClick} className="link" to="/logistics">Logistics / Packers and Movers</Link>
                        <Link onClick={handleItemClick} className="link" to="/ourservices">Our Services</Link> 
                </div>
            </div>
        </>
    );
}

export default MenuItems;