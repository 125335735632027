export const validateProjectForm = (formData, rera) => {
    let errors = {};

    const requiredFields = {
        'projectName': "Project Name",
        'projectAddress': "Project Address",
        'projectCategory': "Project Category",
        'type': "Type",
        'startingPrice': "Starting Price",
        'builderCompanyName': "Builder Company Name",
        'possessionDate': "Possession Date",
        'projectLandArea': "Project Land Area"
    };

    const onlyNumber = {
        "contactNumber": "Contact Number",
        "whatsappNumber": "WhatsApp Number",
        "units": "Units"
    };

    const formDataCopy = JSON.parse(JSON.stringify(formData));

    if (!formDataCopy.reraNumber) {
        if (!rera) {
            errors['reraNumber'] = "RERA Number is required.";
        }
    }

    if (formDataCopy.projectCategory === "residential") {
        if (!formData.bhk) {
            errors['bhk'] = "Bhk is required";
        }
    }

    if (formDataCopy.projectCategory === "commercial") {
        if (!formData.startingSize) {
            errors['startingSize'] = "Starting Size is required";
        }
    }

    for (let field in requiredFields) {
        if (!formDataCopy[field]) {
            errors[field] = `${requiredFields[field]} is required.`;
        }
    }

    for (let field in onlyNumber) {
        if (formDataCopy[field] && isNaN(formDataCopy[field])) {
            errors[field] = `${onlyNumber[field]} should be a number.`;
        }
    }

    let cleanedResidentialData = [];
    let cleanedCommercialData = [];

    if (formDataCopy.projectCategory === "residential") {
        const residentialRequiredFields = {
            residentialType: "Residential Type",
            bhk: "BHK",
            sqFtSize: "Square Foot Size",
            prices: "Prices"
        };
        const { errors: residentialErrors, cleanedData } = validateTableData(formDataCopy.residentialDetails, residentialRequiredFields);
        if (residentialErrors.length) {
            errors.residentialDetails = residentialErrors;
        }
        cleanedResidentialData = cleanedData;
    }

    if (formDataCopy.projectCategory === "commercial") {
        const commercialRequiredFields = {
            commercialType: "Commercial Type",
            sqFtSize: "Square Foot Size",
            prices: "Prices"
        };
        const { errors: commercialErrors, cleanedData } = validateTableData(formDataCopy.commercialDetails, commercialRequiredFields);
        if (commercialErrors.length) {
            errors.commercialDetails = commercialErrors;
        }
        cleanedCommercialData = cleanedData;
    }

    return { errors, cleanedResidentialData, cleanedCommercialData };
};

const validateTableData = (tableData, requiredFields) => {
    let errors = [];
    let nonEmptyRowFound = false;

    const cleanedData = tableData.filter((row, index) => {
        const rowErrors = {};
        const hasValues = Object.values(row).some(value => value !== "");

        if (hasValues) {
            nonEmptyRowFound = true;
            for (let field in requiredFields) {
                if (!row[field]) {
                    rowErrors[field] = `Complete row ${index + 1}: ${requiredFields[field]} is required.`;
                }
            }
            if (Object.keys(rowErrors).length) {
                errors.push(rowErrors);
            }
            return true;
        }
        return false;
    });

    if (!nonEmptyRowFound) {
        errors.push("At least one row should be filled in.");
    }

    return { errors, cleanedData };
};