import "./addProperties.css";
import { useState } from "react";
import { useLocation,useNavigate} from 'react-router-dom';
import axios from 'axios';


const AddProperties = () => {
  const [category, setCategory] = useState("");
  const [propertyType, setPropertyType] = useState("");
  const [bhk, setBhk] = useState("");
  const [title, setTitle] = useState("");
  const [propertyNumber, setPropertyNumber] = useState("");
  const [location, setLocation] = useState("");
  const [budget, setBudget] = useState("");
  const [description, setDescription] = useState("");
  const [contact, setContact] = useState("");
  const [errors, setErrors] = useState({}); // State to manage error messages
  const [loading, setLoading] = useState(false); // For loading indicator

  const Plocation = useLocation();
  const navigate = useNavigate();
  const type = new URLSearchParams(Plocation.search).get('type');

  const isValidType = ['residential', 'commercial', 'plot'].includes(type);

  if (!isValidType) {
    navigate('/');
    return null; // Prevent rendering unnecessary content when redirected
  }

  let dispTitle = "";
  let categories = ["Rental", "Buy-Sale"];
  let propertyTypes = [];
  let bhks = [];

  if(type === "residential"){
    dispTitle = "Residential";
    propertyTypes = ["Flat", "Villa", "Bunglow", "Rowhouse", "Duplex", "Penthouse"];
    bhks = ["1 RK", "1 BHK", "2 BHK", "3 BHK", "4 BHK", "5+ BHK"];
  }
  if(type === "commercial"){
    dispTitle = "Commercial";
    propertyTypes = ["Shop", "Office", "Office Space", "Workspace", "Office Cabin", "Floor Space", "Work Station", "Other"];
  }
  if(type === "plot"){
    dispTitle = "Plots & Lands";
    propertyTypes = [];
  }


  const handleCategoryChange = (event) => {
    setCategory(event.target.value);
    setErrors({ ...errors, category: "" }); // Clear category error
  };

  const handlePropertyTypeChange = (event) => {
    setPropertyType(event.target.value);
    setErrors({ ...errors, propertyType: "" }); // Clear propertyType error
  };

  const handleBhkChange = (event) => {
    setBhk(event.target.value);
    setErrors({ ...errors, bhk: "" }); // Clear bhk error
  };

  const handleTitleChange = (event) => {
    setTitle(event.target.value);
    setErrors({ ...errors, title: "" }); // Clear title error

  };



  const handlePropertyNumberChange = (event) => {
    setPropertyNumber(event.target.value);
    setErrors({ ...errors, propertyNumber: "" }); // Clear propertyNumber error
  };

  const handleLocationChange = (event) => {
    setLocation(event.target.value);
    setErrors({ ...errors, location: "" }); // Clear location error
  };

  const handleBudgetChange = (event) => {
    setBudget(event.target.value);
    setErrors({ ...errors, budget: "" }); // Clear budget error
  };

  const handleDescriptionChange = (event) => {
    setDescription(event.target.value);
    setErrors({ ...errors, description: "" }); // Clear description error
  };

  const handleContactChange = (event) => {
    setContact(event.target.value);
    setErrors({ ...errors, contact: "" }); // Clear contact error
  };



  // const handleInputChange = (event) => {
  //   const {name,value} = event.target;
  //   setFormData((prev) => ({
  //     ...prev,
  //     [name] : value
  //   }));
  // };




  const validateForm = () => {
    let isValid = true;
    const newErrors = {};

    if (category === "") {
      isValid = false;
      newErrors.category = "Please select a category.";
    }

    if (propertyType === "") {
      if(type === "residential" || type === "commercial"){
      isValid = false;
      newErrors.propertyType = "Please select a property type.";
      }
    }

    if (bhk === "") {
      if(type === "residential"){
        isValid = false;
        newErrors.bhk = "Please select a BHK option.";
      }
      
    }

    if (title === "") {
      isValid = false;
      newErrors.title = "Please enter a title for the property.";
    }


    if (location === "") {
      isValid = false;
      newErrors.location = "Please enter the property location.";
    }

    if (budget === "") {
      isValid = false;
      newErrors.budget = "Please enter the budget.";
    } else if (isNaN(budget)) {
      isValid = false;
      newErrors.budget = "Please enter a valid budget (numbers only).";
    }

    if (description === "") {
      isValid = false;
      newErrors.description = "Please provide a description of the property.";
    }

    if (contact === "") {
        isValid = false;
        newErrors.contact = "Please enter your contact information.";
    } else if (contact.length !== 10) {
        isValid = false;
        newErrors.contact = "Contact number must be exactly 10 digits.";
    } else if (isNaN(contact)) {
        isValid = false;
        newErrors.contact = "Please enter a valid contact number (numbers only).";
    }

    setErrors(newErrors);
    return isValid;
  };
  
  let catDisp = "Budget";
  category === "Rental" ? catDisp="Rent" : catDisp="Budget";

  const handleAddProperty = async () => {
    if (validateForm()) {
      setLoading(true); // Start loading

      let formatCategory = category.toLowerCase();
      let formatPropertyType = propertyType.toLowerCase();
      // let formatBhk = bhk.split(" ")[0];
      let formatBhk = bhk.replace(/\s+/g, '');;


      let formData = {
        title,
        property_no: propertyNumber,
        location,
        price: budget, // Map budget to price
        category: formatCategory,
        description,
        contact,
        approved: "false", // Set `approved` for all types
      };
      
      if (type === "residential") {
        formData.bhk = formatBhk;
        formData.property_type = formatPropertyType;
      } else if (type === "commercial") {
        formData.property_type = formatPropertyType; // Only for commercial
      }
      
      
      
      // console.log(formData);
      // setLoading(false);
      // return;


      try {
        const response = await axios.post('https://property-stock-backend.vercel.app/'+ type +'/not-approved/add-property', formData, {
          headers: {
            'Content-Type': 'application/json',
          },
        });
      
        if (response.data.success) { // Check if the success field is true
          alert("Property Details Added Successfully");
          // console.log("Property added successfully:", response.data.message);
          
        } else {
          alert("Some Error Occured while adding property");
          console.error("Failed to add property:", response.data.error || "Unknown error occurred");
        }
      } catch (error) {
        console.error("Error occurred while adding property:", error);
        alert("Some Error Occured while adding property");
      } finally {
        setLoading(false); // End loading
        window.location.reload();
      }
    }
  };
  

  return (
    <div className="addContainer">
      <div className="top">
        <h1>Add {dispTitle} Property</h1>
      </div>

      <div className="bottom">

        <div className="left">

          <div className="leftData">

            <div className="standard title">
              <h4>Title</h4>
              <input type="text" placeholder="Enter Title" value={title} onChange={handleTitleChange} disabled={loading} />
              {errors.title && <span className="error">{errors.title}</span>}
            </div>

            <div className="standard title">
              <h4>Property Number</h4>
              <input type="number" placeholder="Enter Property Number" value={propertyNumber} onChange={handlePropertyNumberChange} disabled={loading} />
              {errors.propertyNumber && <span className="error">{errors.propertyNumber}</span>}
            </div>

            <div className="standard title">
              <h4>Location</h4>
              <input type="text" placeholder="Enter Location" value={location} onChange={handleLocationChange} disabled={loading} />
              {errors.location && <span className="error">{errors.location}</span>}
            </div>

            <div className="standard title">
              <h4>{catDisp}</h4>
              <input type="number" placeholder={"Enter " + catDisp} value={budget} onChange={handleBudgetChange} disabled={loading} />
              {errors.budget && <span className="error">{errors.budget}</span>}
            </div>

            <div className="dropdown-list">

              <div className="ddStandard">
                <h4>Category</h4>
                <select id="category" value={category} onChange={handleCategoryChange} disabled={loading} >
                  <option value="">Select Category</option>
                  {categories.map((cat, index) => (
                    <option key={index} value={cat}>
                      {cat}
                    </option>
                  ))}
                </select>
                {errors.category && <span className="error">{errors.category}</span>}
              </div>

              {/* Conditionally render Property Type dropdown */}
              {type !== 'plot' && (
                <div className="ddStandard">
                  <h4>Property Type</h4>
                  <select id="propertyType" value={propertyType} onChange={handlePropertyTypeChange} disabled={loading} >
                    <option value="">Select Property Type</option>
                    {propertyTypes.map((type, index) => (
                      <option key={index} value={type}>
                        {type}
                      </option>
                    ))}
                  </select>
                  {errors.propertyType && <span className="error">{errors.propertyType}</span>}
                </div>
              )}

              {/* Conditionally render BHK dropdown */}
              {(type !== 'plot') && (type !== 'commercial' && (
                <div className="ddStandard">
                  <h4>BHK</h4>
                  <select id="bhk" value={bhk} onChange={handleBhkChange} disabled={loading}>
                    <option value="">Select BHK</option>
                    {bhks.map((bhk, index) => (
                      <option key={index} value={bhk}>
                        {bhk}
                      </option>
                    ))}
                  </select>
                  {errors.bhk && <span className="error">{errors.bhk}</span>}
                </div>
              ))}

            </div>

            <div className="standard title">
              <h4>Description</h4>
              <textarea className="" placeholder="Enter Description" value={description} onChange={handleDescriptionChange} disabled={loading} />
              {errors.description && <span className="error">{errors.description}</span>}
            </div>
            
            <div className="standard contact">
              <h4>Contact</h4>
              <input type="number" placeholder="Enter Contact" value={contact} onChange={handleContactChange} disabled={loading} />
              {errors.contact && <span className="error">{errors.contact}</span>}
            </div>

          </div>
        </div>

        <div className="right">
          <button onClick={handleAddProperty} disabled={loading}>
            {loading ? <div className="spinner"></div> : "Add Property"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddProperties;