import "./npvm.css";
import shareLogo from "../../Assets/newShare.svg";
import defaultImg from "../../Assets/defaultImg.svg";
import callLogo from '../../Assets/callLogo.svg';
import waLogo from '../../Assets/whatsapp.svg';
import { useNavigate, useParams } from "react-router-dom";
import {useState, useEffect} from "react";
import axios from "axios";
import NewProjectTable from "../NewProjectTable/NewProjectTable";
import {options} from "../../Assets/options";
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import BookingOfferButton from "../BookingOfferBtn/BookingOfferButton";
import { Helmet } from 'react-helmet';





const NewProjectViewMore = () => {

    const { id } = useParams();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);

    const [adInfo, setAdInfo] = useState([]);





    const convertResidentialType = (residentialType) => {
        // Check if the type exists in the residentialType mapping
        return options.residentialType[data.type] || 'Unknown Type';
    };

    const convertCommercialType = (commercialType) => {
        // Check if the type exists in the residentialType mapping
        return options.commercialType[data.type] || 'Unknown Type';
    };

    const convertBhk = (bhk) => {
        return options.bhk[data.bhk] || 'Unknown Type';
    }

    // Function to get the value from contactDetails by key
    const convertContactDetail = (key) => {
        return options.contactDetails[key] || 'Key not found';
    };
  

    let convertedType;

    if(data.projectCategory === "residential")
    {
        convertedType = convertResidentialType(data.type);
    }
    if(data.projectCategory === "commercial")
    {
        convertedType = convertCommercialType(data.type);
    }

    


    useEffect(() => {

        let url = "https://property-stock-backend.vercel.app/project/get/" + id;
        window.scrollTo(0, 0);

        const fetchData = async () => {
            try {
                const response = await axios.get(url);
                const apiData = response.data.response[0];
                setData(apiData);
                console.log(apiData);
                setAdInfo(apiData.additionalInformation[0]);

                // setData((prev) => ({
                //     ...prev,
                //     additionalInformation : apiData.additionalInformation[0]
                // }));
            } catch (error) {
                console.error('Error fetching Data', error);
            }
            finally {
                setLoading(false); 
            }
        };
        fetchData();

    }, [id]);




    const shareLink = async () => {
        try {
            const currentUrl = window.location.href;
            // const message = `Hey! Check out this property by clicking on the link "${url}"`;
      
            if (navigator.share) {
              await navigator.share({
                title: document.title,
                // text: message,
                url: currentUrl
              });
            } else {
              throw new Error('Web Share API not supported.');
            }
          } catch (error) {
            console.error('Error sharing link:', error);
            // Fallback: Provide alternative method for sharing if Web Share API is not supported or fails
            alert('Link sharing failed. Please try again later.');
          }
    };

    // const shareLink = async () => {
    //     try {
    //         const currentUrl = window.location.href;
    //         const imageUrl = data.projectImages; // Assuming data.projectImages is a URL to the image
    //         const response = await fetch(imageUrl);
    //         const blob = await response.blob();
    //         const file = new File([blob], 'property-image.jpg', { type: blob.type });
    
    //         // Check if the Web Share API supports sharing files
    //         if (navigator.share && navigator.canShare({ files: [file] })) {
    //             await navigator.share({
    //                 title: document.title,
    //                 text: 'Check out this property',
    //                 url: currentUrl,
    //                 files: [file]
    //             });
    //         } else {
    //             throw new Error('Web Share API not supported or file sharing not possible.');
    //         }
    //     } catch (error) {
    //         console.error('Error sharing link with image:', error);
    //         alert('Link sharing failed. Please try again later.');
    //     }
    // };
    

    const dispType = data.projectCategory === "residential" ? "Residential"
    :
    data.projectCategory === "commercial" ? "Commercial" 
    : [];

    const priceConverter = (value) => {
        if (value >= 10000000) {
          return `${(value / 10000000).toFixed(2)} Cr`;
        } else if (value >= 100000) {
          return `${(value / 100000).toFixed(2)} L`;
        } else if (value >= 1000) {
          return `${(value / 1000).toFixed(2)} K`;
        } else {
          return value;
        }
    };


    

    // const redirectToWhatsApp = () => {
    //     const whatsappURL = "https://wa.me/+91" + data.whatsappNumber; 
    //     window.location.href = whatsappURL;
    // };

    // const dialNumber = () => {
    //     window.open(`tel:${data.contactNumber}`, '_self');
    // };

    const redirectToWhatsApp = () => {
        const whatsappURL = `https://wa.me/+91${data.whatsappNumber}`; 
        window.open(whatsappURL, '_blank');
    };
    
    const dialNumber = () => {
        window.open(`tel:${data.contactNumber}`, '_self');
    };
    


    const firstColumnItems = data.amenities ? data.amenities.slice(0, 15) : [];
    const secondColumnItems = data.amenities ? data.amenities.slice(15, 29) : [] ;

    
    const isAdditionalInfoEmpty = (info) => {
        return !info || Object.values(info).every(value => !value.trim());
    };


    // const slideImages = [
    //     data.floorPlans1,
    //     data.floorPlans2,
    //     data.floorPlans3,
    //     data.floorPlans4,
    // ];

    const slideImages = [
        data.floorPlans1,
        data.floorPlans2,
        data.floorPlans3,
        data.floorPlans4,
    ].filter(plan => plan); // This filters out any falsy values like undefined or null
    

    // console.log(slideImages);

    const blueIcon = new L.Icon({
        iconUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.9.4/images/marker-icon.png',
        iconSize: [25, 41],
        iconAnchor: [12, 41],
        popupAnchor: [1, -34],
        shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.9.4/images/marker-shadow.png',
        shadowSize: [41, 41],
    });

    function isEmpty(value) {
        if (value == null) return true;
        if (typeof value === 'object' && Object.keys(value).length === 0) return true;
        if (Array.isArray(value) && value.length === 0) return true;
        if (typeof value === 'string' && value.trim().length === 0) return true;
        return false;
      }





    return(
        <>
        <Helmet>
            <meta property="og:title" content={data.projectName} />
            <meta property="og:image" content={data.projectImages} />
            <meta property="og:url" content={window.location.href} />
            <meta property="og:type" content="website" />
        </Helmet>
        {loading ? <div className="spinner"></div> : ""}

            <div className="npvm">


            {/* Part 1 Image */}
                <div className="npvm-part1">
                    <img src={ data.projectImages ? data.projectImages : defaultImg} alt="Main" className="mainImg"/>
                </div>


            {/* Part 2 Title */}
                <div className="npvm-part2">
                    <h1>{data.projectName}</h1>
                    <button className="nvpm-share-btn" onClick={shareLink}>
                        <img src={shareLogo} alt="ShareButton"/>
                    </button>
                </div>


            {/* Part 3 Primary Details */}
                <div className="npvm-part3">

                    <div className="one">
                        <div className="npvmDispText">
                            <h4>Project Address</h4>
                            <h3>{data.projectAddress}</h3>
                        </div>
                        <div className="npvmDispText">
                            <h4>Project Category</h4>
                            <h3>{dispType}</h3>
                        </div>
                    </div>

                    <div className="two">
                        <div className="npvmDispText">
                            <h4>{dispType} Type</h4>
                            <h3>{convertedType}</h3>
                        </div>

                        {dispType === "Residential" ? 
                            <div className="npvmDispText">
                                <h4>BHK</h4>
                                <h3>{convertBhk(data.bhk)}</h3>
                            </div>
                        :
                            <div className="npvmDispText">
                                <h4>Starting Size</h4>
                                <h3>{data.startingSize + " Sq.ft."}</h3>
                            </div>
                        }

                    </div>

                    <div className="three">
                        <h5>{data.contactDetails ? convertContactDetail(data.contactDetails) : ""}</h5>
                        {/* <h5>Broker / Agent</h5> */}
                        <div className="btnDiv">

                            {data.contactNumber ? ( 
                            <button onClick={dialNumber} className="callBtn">
                                <img src={callLogo} alt="Call Btn" onClick={dialNumber}/>
                            </button>
                            ): null}

                            {data.whatsappNumber ? ( 
                            <button className="waBtn" onClick={redirectToWhatsApp}>
                                <img onClick={redirectToWhatsApp} src={waLogo} alt="Call Btn"/>
                            </button>
                            ): null}
                        </div>
                        <h4>Starting Price</h4>
                        <h3>INR {priceConverter(data.startingPrice)}</h3>
                        <button className="nvpm-share-btn" onClick={shareLink}>
                            <img src={shareLogo} className="shareBtnImage" alt="ShareBtn"/>
                        </button>
                        
                    </div>
                </div>


                {/* Part 4 Project Summary */}
                <div className="npvm-part4">

                    <h1>• Project Summary</h1>

                    <div className="text">
                            <div className="npvmDispText">
                                <h4>Project Name</h4>
                                <h3>{data.projectName}</h3>
                            </div>

                            {data.projectNumber ?  
                            <div className="npvmDispText">
                                <h4>Project Number</h4>
                                <h3>{data.projectNumber}</h3>
                            </div>
                            : []}


                            {data?.ownershipType ? (
                            <div className="npvmDispText">
                                <h4>Ownership Type</h4>
                                <h3>{data.ownershipType}</h3>
                            </div>
                            ) : null}



                            {data.reraNumber ? 
                            <div className="npvmDispText">
                                <h4>RERA Number</h4>
                                <h3>{data.reraNumber}</h3>
                            </div>
                            : []}



                            <div className="npvmDispText">
                                <h4>Possession Date</h4>
                                <h3>{data.possessionDate}</h3>
                            </div>
                            <div className="npvmDispText">
                                <h4>Builder Company Name</h4>
                                <h3>{data.builderCompanyName}</h3>
                            </div>
                            <div className="npvmDispText">
                                <h4>Project Land Area</h4>
                                <h3>{data.projectLandArea}</h3>
                            </div>

                            {data.units ? 
                            <div className="npvmDispText">
                                <h4>Total Number of {data.projectCategory === "residential" ? "Floors" : "Units"}</h4>
                                <h3>{data.units}</h3>
                            </div>
                            : []}


                    </div>
                    <div className="table">
                        <NewProjectTable 
                        commercialDetails={data.commercialDetails} 
                        residentialDetails={data.residentialDetails} 
                        />
                    </div>

                    

                </div>


                {/* Amenities */}
                {(firstColumnItems.length > 0 || secondColumnItems.length > 0 || data.projectDetails) ? (
                <div className="npvm-part5">
                    <h1>• Amenities</h1>

                    <div className="amenities">
                    <div className="fixed-amenities">
                        <div className="column">
                        {firstColumnItems.map((item, index) => (
                            <h3 key={index}>{item}</h3>
                        ))}
                        </div>
                        {secondColumnItems.length > 0 && (
                        <div className="column">
                            {secondColumnItems.map((item, index) => (
                            <h3 key={index}>{item}</h3>
                            ))}
                        </div>
                        )}
                    </div>
                    {data.projectDetails ? (
                    <textarea disabled value={data.projectDetails} />
                    ) : null}
                    </div>
                </div>
                ) : null}



                {/* Booking Offers */}
                {data?.bookingOfferType && !isEmpty(data?.bookingOffer) ? (
                <div className="npvm-part6">

                    <h1>• Booking Offers</h1>
                    <div className="bkoBtnContainer">
                        <BookingOfferButton content={data.bookingOffer} type={data.bookingOfferType}/>
                    </div>
                </div>
                ) : null}


                {/* Features */}
                {data?.features ? (
                <div className="npvm-part7">
                    
                    <h1>• Features</h1>
                    <div>
                        <textarea disabled value={data.features}/>
                    </div>
                    
                </div>
                ) : null}


                {/* Free Text Box */}
                {data?.freeText?.trim() ? (
                <div className="npvm-part8">
                    
                        <textarea disabled value={data.freeText} />
                    
                </div>
                ) : null}



                {/* Additional Information */}
                {data?.additionalInformation?.length > 0 && !isAdditionalInfoEmpty(data.additionalInformation[0]) ? (
                <div className="npvm-part9">
                    <h1>• Additional Information</h1>
                    <div className="text">


                    {data?.additionalInformation[0].transportation?    
                    <div className="npvmDispText">
                        <h4>Transportation</h4>
                        <h3>{data.additionalInformation[0].transportation}</h3>
                    </div>
                    : null} 


                    {data?.additionalInformation[0].airport? 
                    <div className="npvmDispText">
                        <h4>Airport</h4>
                        <h3>{data.additionalInformation[0].airport}</h3>
                    </div>
                    : null} 



                    {data?.additionalInformation[0].mall? 
                    <div className="npvmDispText">
                        <h4>Mall / Marketplace</h4>
                        <h3>{data.additionalInformation[0].mall}</h3>
                    </div>
                    : null} 



                    {data?.additionalInformation[0].school? 
                    <div className="npvmDispText">
                        <h4>School / College</h4>
                        <h3>{data.additionalInformation[0].school}</h3>
                    </div>
                    : null} 



                    {data?.additionalInformation[0].railwayStation? 
                    <div className="npvmDispText">
                        <h4>Railway Station</h4>
                        <h3>{data.additionalInformation[0].railwayStation}</h3>
                    </div>
                    : null} 


                    {data?.additionalInformation[0].metroStation? 
                    <div className="npvmDispText">
                        <h4>Metro Station</h4>
                        <h3>{data.additionalInformation[0].metroStation}</h3>
                    </div>
                    : null} 


                    {data?.additionalInformation[0].hotel? 
                    <div className="npvmDispText">
                        <h4>Hotel</h4>
                        <h3>{data.additionalInformation[0].hotel}</h3>
                    </div>
                    : null} 


                    {data?.additionalInformation[0].restaurant? 
                    <div className="npvmDispText">
                        <h4>Restaurants</h4>
                        <h3>{data.additionalInformation[0].restaurant}</h3>
                    </div>
                    : null} 


                    {data?.additionalInformation[0].foodCourt? 
                    <div className="npvmDispText">
                        <h4>Food Court</h4>
                        <h3>{data.additionalInformation[0].foodCourt}</h3>
                    </div>
                    : null} 


                    {data?.additionalInformation[0].hospitals? 
                    <div className="npvmDispText">
                        <h4>Hospitals</h4>
                        <h3>{data.additionalInformation[0].hospitals}</h3>
                    </div>
                    : null} 

                    {data?.additionalInformation[0].entertainment? 
                    <div className="npvmDispText">
                        <h4>Entertainment</h4>
                        <h3>{data.additionalInformation[0].entertainment}</h3>
                    </div>
                    : null} 

                    </div>
                </div>
                ) : null}



                
                {/* Bank / Finance Details */}
                {data.bankOrFinance?
                <div className="npvm-part10">
                    <h1>• Bank / Finance</h1>
                    <h3>{data.bankOrFinance}</h3>
                </div>
                : [] }


                {/* Floor Plans */}
                {data && data.floorPlans1 ? (
                <div className="npvm-part11">
                    <h1>• Floor Plans</h1>
                    <div className="floorPlans">
                    <Slide
                        transitionDuration={400} // Transition duration in milliseconds
                        duration={3000}
                        infinite={true}
                        pauseOnHover={true}
                    >
                        {slideImages.map((each, index) => (
                        <div key={index} className="each-slide">
                            <img src={each} alt={`Slide ${index + 1}`} />
                        </div>
                        ))}
                    </Slide>
                    </div>
                </div>
                ) : (
                <></>
                )}

                
                {/* Map or Location */}
                {/* {data?.map && data.map.latitude ? (
                <div className="npvm-part12">
                    <h1>• Map / Location</h1>
                    <MapContainer
                    center={[data.map.latitude, data.map.longitude]}
                    zoom={13}
                    style={{ height: '400px', width: '80%' }}
                    >
                    <TileLayer
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    />
                    <Marker position={[data.map.latitude, data.map.longitude]} icon={blueIcon}>
                        <Popup>Your Location</Popup>
                    </Marker>
                    </MapContainer>
                </div>
                ) : (

                <></>
                )} */}


            </div>

            
        </>
    );
}

export default NewProjectViewMore;
