import "./filter.css";
import {useState, useEffect, useContext} from "react";
import {useNavigate} from "react-router-dom"
import Slider from "react-slider";
import axios from 'axios';
import { FilterDataContext } from '../../Context/FilterContext';

const RFilter = () => {

    const { updateFilteredData } = useContext(FilterDataContext);
    const [data, setData] = useState([]);
    const[location, setLocation] = useState("");

    const navigate = useNavigate();
    

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get('https://property-stock-backend.vercel.app/residential/approved');
                const data = response.data.response;
                setData(data);
            } catch (error) {
                console.error('Error fetching Data', error);
            }
        };
        fetchData();
    }, [])

    
    const handleLocationChange = (event) => {
        setLocation(event.target.value);
    }

    const [selectedCategory, setSelectedCategory] = useState('rental');
    const handleCategoryChange = (event) => {
        setSelectedCategory(event.target.value);
    };

    const [selectedType, setSelectedType] = useState('flat');
    const handleTypeChange = (event) => {
        setSelectedType(event.target.value);
    };

    const [selectedBhk, setSelectedBhk] = useState(null);
    const handleBhkChange = (event) => {
        const value = event.target.value === '' ? null : event.target.value;
        setSelectedBhk(value);
    };


    const MIN = 0;
    const MAX = 100000000;
    const [values,setValues] = useState([MIN,MAX]);
    // let minValue = toWords.convert(values[0]);
    // let maxValue = toWords.convert(values[1]);

    const formatSliderValue = (value) => {
        if (value >= 10000000) {
          return `${(value / 10000000).toFixed(2)} Cr`;
        } else if (value >= 100000) {
          return `${(value / 100000).toFixed(2)} L`;
        } else if (value >= 1000) {
          return `${(value / 1000).toFixed(2)} K`;
        } else {
          return value.toString();
        }
    };


        let trimLocation = location.trim();

        let filterSearch = data.filter(item => {
            return item.location.toLowerCase().includes(trimLocation.toLowerCase());
        });
        // .map(item => item.location);

        
        // if(location.trim().length === 0){
            // let filter2 = filterSearch.filter(item => item.category === selectedCategory);
            let filter2 = location.trim().length === 0? data.filter(item => item.category === selectedCategory) : filterSearch.filter(item => item.category === selectedCategory);
            let filter3 = filter2.filter(item => item.property_type === selectedType);
            let filter4 = selectedBhk === null ? filter3 : filter3.filter(item => item.bhk === selectedBhk || item.bhk === null);
            let filter5 = filter4.filter(item => 
            item.price &&
            item.price >= values[0] &&
            item.price <= values[1]
            );
        // }

    
    const submit = (event) => {
        // console.log(selectedCategory + selectedType + selectedBhk + values[0] + "-" + values[1]);
        // console.log(filter5);
        event.preventDefault();
        updateFilteredData(filter5.sort((a, b) => Number(b.rating) - Number(a.rating)));
        navigate('/residential/results');
    }


    return(
        <>
        <div className="filter-body">
            
            <div className="filter-title">
                <h1>Residential Property Search</h1>
            </div>

            <form onSubmit={submit}>
            <div className="filter-location">
                <input
                    type="text"
                    placeholder="Enter Location"
                    onChange={handleLocationChange}
                    value={location}
                />
            </div>
            <div className="filter-category">
                <div className="text">
                    <h2>Category :</h2>
                </div>
                <div className="radio-container">
                <label className="radio-label">
                    <input
                    type="radio"
                    value="rental"
                    checked={selectedCategory === 'rental'}
                    onChange={handleCategoryChange}
                    className="radio-input"
                    />
                    <div className={`radio-button ${selectedCategory === 'rental' ? 'checked' : ''}`}>
                    Rental
                    </div>
                </label>
                <label className="radio-label">
                    <input
                    type="radio"
                    value="buy-sale"
                    checked={selectedCategory === "buy-sale"}
                    onChange={handleCategoryChange}
                    className="radio-input"
                    />
                    <div className={`radio-button ${selectedCategory === "buy-sale" ? 'checked' : ''}`}>
                    Buy/Sale
                    </div>
                </label>
                </div>
            </div>

            <div className="filter-type">
                <div className="text">
                        <h2>Property Type :</h2>
                </div>

                <div className="radio-container">
                    <label className="radio-label">
                        <input
                        type="radio"
                        value="flat"
                        checked={selectedType === 'flat'}
                        onChange={handleTypeChange}
                        className="radio-input"
                        />
                        <div className={`radio-button ${selectedType === 'flat' ? 'checked' : ''}`}>
                        Flat
                        </div>
                    </label>
                    <label className="radio-label">
                        <input
                        type="radio"
                        value="villa"
                        checked={selectedType === 'villa'}
                        onChange={handleTypeChange}
                        className="radio-input"
                        />
                        <div className={`radio-button ${selectedType === 'villa' ? 'checked' : ''}`}>
                        Villa
                        </div>
                    </label>
                    <label className="radio-label">
                        <input
                        type="radio"
                        value="bunglow"
                        checked={selectedType === 'bunglow'}
                        onChange={handleTypeChange}
                        className="radio-input"
                        />
                        <div className={`radio-button ${selectedType === 'bunglow' ? 'checked' : ''}`}>
                        Bunglow
                        </div>
                    </label>
                    <label className="radio-label">
                        <input
                        type="radio"
                        value="rowhouse"
                        checked={selectedType === 'rowhouse'}
                        onChange={handleTypeChange}
                        className="radio-input"
                        />
                        <div className={`radio-button ${selectedType === 'rowhouse' ? 'checked' : ''}`}>
                        Rowhouse
                        </div>
                    </label>
                    <label className="radio-label">
                        <input
                        type="radio"
                        value="duplex"
                        checked={selectedType === 'duplex'}
                        onChange={handleTypeChange}
                        className="radio-input"
                        />
                        <div className={`radio-button ${selectedType === 'duplex' ? 'checked' : ''}`}>
                        Duplex
                        </div>
                    </label>
                    <label className="radio-label">
                        <input
                        type="radio"
                        value="penthouse"
                        checked={selectedType === 'penthouse'}
                        onChange={handleTypeChange}
                        className="radio-input"
                        />
                        <div className={`radio-button ${selectedType === 'penthouse' ? 'checked' : ''}`}>
                        Penthouse
                        </div>
                    </label>
                    </div>
            </div>
            <div className="filter-type">
                <div className="text">
                        <h2>BHK :</h2>
                </div>

                <div className="radio-container">
                <input
                    type="radio"
                    value=""
                    checked={selectedBhk === null}
                    onChange={handleBhkChange}
                    className="radio-input"
                />
                        <label className="radio-label">
                            <input
                            type="radio"
                            value="1RK"
                            checked={selectedBhk === '1RK'}
                            onChange={handleBhkChange}
                            className="radio-input"
                            />
                            <div className={`radio-button ${selectedBhk === '1RK' ? 'checked' : ''}`}>
                            1 RK
                            </div>
                        </label>
                        <label className="radio-label">
                            <input
                            type="radio"
                            value="1BHK"
                            checked={selectedBhk === '1BHK'}
                            onChange={handleBhkChange}
                            className="radio-input"
                            />
                            <div className={`radio-button ${selectedBhk === '1BHK' ? 'checked' : ''}`}>
                            1 BHK
                            </div>
                        </label>
                        <label className="radio-label">
                            <input
                            type="radio"
                            value="2BHK"
                            checked={selectedBhk === '2BHK'}
                            onChange={handleBhkChange}
                            className="radio-input"
                            />
                            <div className={`radio-button ${selectedBhk === '2BHK' ? 'checked' : ''}`}>
                            2 BHK
                            </div>
                        </label>
                        <label className="radio-label">
                            <input
                            type="radio"
                            value="3BHK"
                            checked={selectedBhk === '3BHK'}
                            onChange={handleBhkChange}
                            className="radio-input"
                            />
                            <div className={`radio-button ${selectedBhk === '3BHK' ? 'checked' : ''}`}>
                            3 BHK
                            </div>
                        </label>
                        <label className="radio-label">
                                <input
                                type="radio"
                                value="4BHK"
                                checked={selectedBhk === '4BHK'}
                                onChange={handleBhkChange}
                                className="radio-input"
                                />
                                <div className={`radio-button ${selectedBhk === '4BHK' ? 'checked' : ''}`}>
                                4 BHK
                                </div>
                        </label>
                        <label className="radio-label">
                                <input
                                type="radio"
                                value="5+BHK"
                                checked={selectedBhk === '5+BHK'}
                                onChange={handleBhkChange}
                                className="radio-input"
                                />
                                <div className={`radio-button ${selectedBhk === '5+BHK' ? 'checked' : ''}`}>
                                5 + BHK
                                </div>
                        </label>
                    </div>
            </div>
            

            <div className="filter-price">
                <div className="sliderContainer">
                    <Slider className={"slider"}
                            onChange={setValues}
                            value={values}
                            min={MIN}
                            max={MAX} 
                    />
                </div>

                <div className="label">
                    <h2>{formatSliderValue(values[0])}</h2>
                    <h2>{formatSliderValue(values[1])}</h2>
                </div>
                
            </div>

            <div className="filter-btn">
                <input className="submitBtn" type="submit"/>
            </div>
            </form>
        </div>
        <div className="extra"></div>
        
        </>
    );
}

export default RFilter;