import "./addNewProject.css";
import NewProjectForm from "../../Components/NewProjectForm/NewProjectForm";



const AddNewProject = () => {
    

    return(
        <div className="newProjectForm">
            <NewProjectForm/>
        </div>
    );
}

export default AddNewProject;