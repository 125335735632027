export const amenitiesCol1 = [
    "Vaastu Compliant",
    "Car Parking",
    "Home Automation",
    "Commercial Automation",
    "Visitor Parking",
    "Security",
    "Digital Security",
    "e-Security",
    "GYM",
    "Hi-Tech GYM",
    "Club House",
    "Multipurpose Lawn",
    "Yoga Room",
    "Zumba Room",
    "Garden / Park",
];

export const amenitiesCol2 = [
    "Children Play Area",
    "Day Care Area",
    "Indoor Game Room",
    "Swimming Pool",
    "SYK Lounge",
    "Gaming Zone",
    "Barbeque Area",
    "Commercial Elevator",
    "Lift",
    "Intercom",
    "Wifi",
    "Gas Pipeline",
    "Power Backup",
    "Shared Work Space"
];