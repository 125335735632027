import "./newProjectCard.css";
import DefaultImgLogo from "../../Assets/defaultImg.svg";
import {Navigate, useNavigate} from "react-router-dom";
import {options} from "../../Assets/options";
import {useState} from "react";


const NewProjectCard = ({ project }) => {



    const { _id, projectName, projectCategory, projectAddress, possessionDate, bhk, startingPrice, type, startingSize, projectImages } = project;



    const priceConverter = (value) => {
        if (value >= 10000000) {
          return `${(value / 10000000).toFixed(2)} Cr`;
        } else if (value >= 100000) {
          return `${(value / 100000).toFixed(2)} L`;
        } else if (value >= 1000) {
          return `${(value / 1000).toFixed(2)} K`;
        } else {
          return value.toString();
        }
    };

    const convertResidentialType = (residentialType) => {
        // Check if the type exists in the residentialType mapping
        return options.residentialType[type] || 'Unknown Type';
    };

    const convertCommercialType = (residentialType) => {
        // Check if the type exists in the residentialType mapping
        return options.commercialType[type] || 'Unknown Type';
    };

    const convertBhk = (bhk) => {
        return options.bhk[bhk] || 'Unknown Type';
    }

    let convertedType;

    if(projectCategory === "residential")
    {
        convertedType = convertResidentialType(project.type);
    }
    if(projectCategory === "commercial")
    {
        convertedType = convertCommercialType(project.type);
    }


    let diff = "";
    if(bhk){
        diff = "BHK : " + convertBhk(project.bhk);
    }
    if(startingSize){
        diff = "Starting Size : " + startingSize + " Sq.Ft.";
    }

    const navigate = useNavigate();
    const handleClick = () => {
        navigate(`/newprojects/viewmore/${_id}`);
    }


    const nameLength = window.innerWidth > 600 ? 45 : 40;
    const LocationLength = window.innerWidth > 600 ? 56 : 42;



    const truncate = (name, length) => {
        // Check if the length of the name exceeds 20 characters
        if (name.length > length) {
          // Return the first 20 characters followed by "..."
          return name.slice(0, length) + '...';
        }
        // If within the limit, return the name as is
        return name;
    };


    return(
        <>
            <button className="np-card" onClick={handleClick}>
                <div className="np-card-left">
                    <div className="np-card-left-1">
                        <img src={projectImages.length === 0 ? DefaultImgLogo : projectImages} alt="Project Image"/>
                    </div>
                    <div className="np-card-left-2">
                        <h4>{convertedType}</h4>
                    </div>
                </div>
                <div className="np-card-right">
                    <div className="np-card-right-1">
                        <h2>{truncate(projectName, nameLength)}</h2>
                        <h4>Location : {truncate(projectAddress,LocationLength)}</h4>
                        <h4>Posession : {possessionDate}</h4>
                        <h4>{diff}</h4>
                    </div>
                    <div className="np-card-right-2">
                        <h4>Starting Price</h4>
                        <h3>{priceConverter(startingPrice)}</h3>
                    </div>
                </div>
            </button>
        </>
    );
}

export default NewProjectCard;