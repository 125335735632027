import React, { createContext, useState } from 'react';

const FilterDataContext = createContext(null);

function FilterDataProvider({ children }) {
  const [filteredData, setFilteredData] = useState([]);
  const [pageType, setPageType] = useState([]);

  const updateFilteredData = (newData) => {
    setFilteredData(newData);
  };

  const updatePageType = (newPageType) => {
    setPageType(newPageType);
  };


  return (
    <FilterDataContext.Provider value={{
      filteredData,
      updateFilteredData,
      pageType,
      updatePageType,
    }}>
      {children}
    </FilterDataContext.Provider>
  );
}

export { FilterDataContext, FilterDataProvider };
