import "./viewMore.css";
import { useNavigate, useParams } from "react-router-dom";
import backBtn from "../../Assets/backBtn.svg";
import whatsappIcon from "../../Assets/whatsapp.svg";
import { useState, useEffect } from 'react';
import axios from "axios";
import Share from "../../Assets/shareLogo.svg";

const ViewMore = () => {

    const navigate = useNavigate();

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [data, setData] = useState([]);

    const { id } = useParams();
    // const { pageType } = useContext(FilterDataContext);
    const[pageType, setPageType] = useState("");

    useEffect(() => {
        const url = new URL(window.location.href);
        const propertyTypeFromURL = url.pathname.split('/')[1]; // Extract the property type from the URL

        let getUrl = "";
        // Determine URL based on pageType
        if (propertyTypeFromURL === "residential") {
            getUrl = "https://property-stock-backend.vercel.app/residential/get-property/" + id;
            setPageType("residential");
        } else if (propertyTypeFromURL === "commercial") {
            getUrl = "https://property-stock-backend.vercel.app/commercial/get-property/" + id;
            setPageType("commercial");
        } else if (propertyTypeFromURL === "plot&land") {
            getUrl = "https://property-stock-backend.vercel.app/plot/get-property/" + id;
            setPageType("plotland");
        } else {
            setLoading(false); // Invalid pageType, stop loading
            return;
        }

        const fetchData = async () => {
            try {
                const response = await axios.get(getUrl);
                const data = response.data.response;
                setData(data);
            } catch (error) {
                console.error('Error fetching Data', error);
                setError(error.message);
            }
            finally {
                setLoading(false); 
            }
        };
        fetchData();

    }, [id]);
    

    
    // const cardDetails = location.state?.cardDetails;

    if (!data) return <p>Details not found!</p>;


    if (loading) {
        return <div><center>Loading...</center></div>;
    }

    if (error) {
        return <div>Error: {error}</div>; // Display the specific error message
    }

    if (!data || data.length === 0) {
        return <div>No Data Found</div>;
    }


    const priceConverter = (value) => {
        if (value >= 10000000) {
          return `${(value / 10000000).toFixed(2)} Cr`;
        } else if (value >= 100000) {
          return `${(value / 100000).toFixed(2)} L`;
        } else if (value >= 1000) {
          return `${(value / 1000).toFixed(2)} K`;
        } else {
          return value.toString();
        }
    };

    function decodeHTMLEntities(str) {
        return str.replace(/&amp;/g, '&');
    }

    let displayText = data.category === "rental" ? "Rent" : "Budget";

    const showCardType = pageType !== "plotland";
    const showBhk = pageType !== "commercial" && pageType !== "plotland";

    const goBack = () => {
        navigate(-1);
    }

    const redirectToWhatsApp = () => {
        const whatsappURL = "https://wa.me/+917718858689"; 
        window.location.href = whatsappURL;
    };

    const dialNumber = () => {
        window.open(`tel:${data.contact}`, '_self');
    };

    const shareLink = async () => {
        try {
            const url = window.location.href;
            // const message = `Hey! Check out this property by clicking on the link "${url}"`;
      
            if (navigator.share) {
              await navigator.share({
                title: document.title,
                // text: message,
                url: url
              });
            } else {
              throw new Error('Web Share API not supported.');
            }
          } catch (error) {
            console.error('Error sharing link:', error);
            // Fallback: Provide alternative method for sharing if Web Share API is not supported or fails
            alert('Link sharing failed. Please try again later.');
          }
    };

    // console.log(data.rating);


    return(
        <>
            <div className="viewmore-body">

                <div className="topButtons">
                    <div className="left">
                            <button onClick={goBack}><img src={backBtn} alt="backBtnImg"/></button>
                    </div>
                    <div className="middle">
                        <button onClick={shareLink}><img src={Share} alt="sharebutton"/></button>
                    </div>
                    <div className="right">
                        <button onClick={redirectToWhatsApp}>
                                <img src={whatsappIcon} alt="WhatsappLogo"/>
                                <h2>New Project?</h2>
                                <h2 className="hide">+91 7718858689</h2>
                        </button>
                    </div>
                </div>

                <div className="title">
                    <h1>{data.title}</h1>
                </div>

                <div className="data">

                    <div className="left">
                        { data.property_no ? <h2>Property No : {data.property_no}</h2> : null}
                        <h2>Location : {data.location}</h2>
                        <h2>{displayText} : {priceConverter(data.price)} </h2>
                        {/* <h2>Type : <span className="capitalize">{cardDetails.property_type}</span></h2> */}
                        {showCardType && (
                            <h2>
                                Type : <span className="capitalize">{data.property_type}</span>
                            </h2>
                        )}
                        {/* <h2>BHK : {cardDetails.bhk}</h2> */}
                        {showBhk && (
                            <h2>BHK : {data.bhk}</h2>
                        )}

                        <h3 style={{ whiteSpace: 'pre-line' }}>{decodeHTMLEntities(data.description)}</h3>
                        <h2>Contact : <button onClick={dialNumber}>{data.contact}</button></h2>
                    </div>

                    <div className="right">
                        <button onClick={shareLink}><img src={Share} alt="sharebutton"/></button>
                    </div>
                        
                </div>
                <div className="extra">

                </div>

            </div>
        </>
    );
}

export default ViewMore;