import "./newProjectTable.css";
import { useEffect, useState } from 'react';

const NewProjectTable = ({ commercialDetails = [], residentialDetails = []  }) => {

    const tableData = (commercialDetails.length > 0) 
    ? commercialDetails 
    : (residentialDetails.length > 0) 
    ? residentialDetails 
    : [];

    // console.log(tableData);

    const tableType = (commercialDetails.length > 0) 
    ? "Commercial" 
    : (residentialDetails.length > 0) 
    ? "Residential" 
    : [];


        return(
            <div className="tableDiv">
            <table className="table">
                <thead>
                    <tr>
                        <td>{tableType} Type</td>
                        {tableType === "Residential" ? <td>BHK</td> : []}
                        
                        <td>Sq. Ft. Size</td>
                        <td>Prices</td>
                    </tr>
                </thead>
                
                <tbody>
                    {tableData.map((item, index) => (
                        <tr key={index}>
                            {tableType === "Residential" ? <td>{item.residentialType}</td> : <td>{item.commercialType}</td>}
                            {tableType === "Residential" ? <td>{item.bhk}</td> : []}
                            <td>{item.sqFtSize}</td>
                            <td>{item.prices}</td>
                        </tr>
                    ))}
                </tbody>    
            </table>
        </div>
        );
}

export default NewProjectTable;