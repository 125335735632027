import "./whatsapp.css";
import whatsappIcon from "../../Assets/whatsapp.svg";

const Whatsapp = () => {

    const redirectToWhatsApp = () => {
        const whatsappURL = "https://wa.me/+917718858689"; 
        window.location.href = whatsappURL;
      };

    return(
        <>
        <div className="whatsappBox">
            <button onClick={redirectToWhatsApp}>
                <img className="whatsappLogo" src={whatsappIcon} alt="WhatsappLogo"/>
                <h2>on <span className="colorChange">+91 77188 58689</span></h2>
            </button>
        </div>
        </>
    );
}

export default Whatsapp;