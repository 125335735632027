import "./newProjects.css";
import SearchBar from "../../Components/SearchBar/SearchBar";
import NewProjectCard from "../../Components/Cards/NewProjectCard";
import {useState, useEffect, useContext} from "react";
import { FilterDataContext } from '../../Context/FilterContext';

const NewProjectResults = () => {

    const { filteredData } = useContext(FilterDataContext);
    const [loading, setLoading] = useState(true);
    const [text, setText] = useState("Loading...");

    useEffect(() => {
        if (filteredData.length === 0) {
          // Set timeout only when filteredData is empty
          const timeoutId = setTimeout(() => {
            setText('No Properties Available');
            setLoading(false);
          }, 8000);
      
          // Cleanup function to clear the timeout
          return () => clearTimeout(timeoutId);
        } else {
          // Set loading to false if data is available
          setLoading(false);
        }
      }, [filteredData]); // Add filteredData as a dependency
      
      

    return(
        <div className="newProjects">
            <div className="searchBarDiv">
                <SearchBar pageType="newprojects"/>
            </div>

            <div className="titleDiv">
                <h1>New Projects</h1>
            </div>
            {loading ? <div className="spinner"></div> : ""}
            <div className="outputDiv">
                {/* {filteredData.map((project, index) => (
                    <NewProjectCard key={index} project={project} />
                ))} */}

                {filteredData.length !== 0 ? (
                    filteredData.map((project, index) => {
                    return (
                        <NewProjectCard key={index} project={project} />
                    );
                    })
                ) : (
                    <div>{text}</div>
                )}

            </div>

            <div className="extraSpace"></div>
        </div>
    );
}

export default NewProjectResults;